import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FiltersService } from './filters.service';
import { environment } from '../../../environments/environment';
import { map, of, tap } from 'rxjs';
import { NavigationStateService } from './navigation-state.service';

@Injectable({
  providedIn: 'root',
})
export class LastProjectSelectedService {
  readonly #URL = `${environment.apiUrl}`;
  readonly #http = inject(HttpClient);
  readonly #filterService = inject(FiltersService);
  readonly #navigationState = inject(NavigationStateService);

  setFavoriteProject(projectId: number) {
    const body = { projectId };

    if (this.#navigationState.id() !== projectId && projectId) {
      return this.#http
        .post(`${this.#URL}/me/favorite_project`, body)
        .pipe(tap(() => this.#navigationState.setProjectId(projectId)));
    }
    return of();
  }

  getFavProjectId() {
    return this.#http.get<any>(`${this.#URL}/me`).pipe(
      map((res) => {
        if (res.favorite_project) {
          this.#filterService._RESET();
          this.#filterService.isLoaded.set(false);
          this.#filterService.getAllfilters(res.favorite_project).subscribe();
        }
        this.#navigationState.setProjectId(res.favorite_project);
        return res.favorite_project;
      }),
    );
  }
}
